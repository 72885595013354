<!--  -->
<script>
export default {
  components: {},
  data() {
    return {}
  },
  computed: {},
  watch: {},
  created() {
    this._navigator()
  },
  mounted() { },
  methods: {
    _navigator() {
      if (/micromessenger/i.test(navigator.userAgent)) {
        this.$router.push({
          path: '/download-ele/guidance'
        })
      } else {
        this.$router.push({
          path: '/download-ele/appdownload'
        })
      }
    }
  }
}
</script>

<template>
  <div></div>
</template>
